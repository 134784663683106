@import url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Bungee+Inline&family=Fjalla+One&family=Lobster&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Pacifico&family=Permanent+Marker&family=Quintessential&family=Roboto+Condensed:wght@400;700&family=Roboto+Mono:ital,wght@0,600;0,700;1,600;1,700&family=Rowdies:wght@300;400;700&family=Satisfy&display=swap');

html,
body {
  /*margin: 0;*/
  font-family: 'Open Sans', sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  color: rgb(21, 28, 30);
  background-color: black;
  height: 100vh;
}

rect {
  fill: #ffffff00 !important;
  stroke: #00ff00ff !important;
  stroke-width: 1 !important;
}

/******************************************************** HOME PAGE **************************************************************/
.home_page {
  width: 90%;
  height: 90%;
  background: black;
  display: flex;
  flex-direction: row;
  color: white;
}

.left_side {
  background: black;
  justify-content: center;
  align-items: center;
  width: 60%;
  display: flex;
  flex-direction: column;
}

.nav_text {
  font-family: sans-serif;
  font-size: 30px;
  color: white;
  text-decoration: none;
  /*width: auto;*/
  margin-left: 45px;
}
.nav_text span:hover {
  color: #34c672;
}

.nav_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /*background: grey;*/
  margin-top: 80px;
}

/******************************************************** UPLOAD **************************************************************/

.upload {
  background: black;
  color: white;
}

.upload_form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
}

.upload_form h1 {
  font-size: 60px;
}

.inputbox {
  border: none;
  background: transparent;
  border-bottom: 2px solid white;
  width: 300px;
  color: white;
  margin-bottom: 50px;
  font-size: 25px;
  /*margin-left: 10px;*/
}

.input_form {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  /*margin-right: 50px;*/
  border-right: 1px solid grey;
  padding-right: 50px;
  /*position: absolute;*/
}

input:focus {
  outline: none;
  border-bottom: 2px solid #34c672;
}

.primary_btn {
  background: #34c672;
  border: none;
  height: 40px;
  font-size: 27px;
  margin-top: 60px;
}

.upload_btn {
  background: #34c672;
  border: none;
  width: 30%;
  height: 40px;
  font-size: 27px;
  margin-top: 60px;
}

a {
  color: white;
  text-decoration: none;
  font-size: 22px;
}
a:hover {
  text-decoration: underline;
  color: #34c672;
}

/******************************************************** IMAGE APP **************************************************************/

.side_bar {
  background: black;
  width: 13%;
  height: 100vh;
}
.image_app {
  /* width: 87%;
  height: 100vh; */
}

.dropdown {
  color: white;
  margin: 0 2px 20px 0;
}

.dropdown_btn {
  width: 200px !important;
  font-family: sans-serif !important;
  font-size: 20px;
  color: black !important;
  /*background: black !important;*/
  /* overflow: hidden; */
}

input[type='radio']:before {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:before {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ffa500;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
/******************************************************** PROGRESS BAR **************************************************************/

.CircularProgressbar {
  width: 100%;
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #34c672;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #fff;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #34c672;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted
  .CircularProgressbar-background {
  fill: #34c672;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}
